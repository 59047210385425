<template>
	<div>
		<div class="travel-shell">
			<div class="travel-title">
				疫情防控登记
			</div>

			<van-form
				@submit="onSubmit"
				label-width="8em"
				validate-trigger="onChange"
				ref="form"
			>
				<van-field
					required
					:disabled="isSubmit"
					:error="false"
					v-model="info.name"
					name="name"
					label="姓名"
					placeholder="请输入姓名"
					:rules="[{ required: true, message: '请填写姓名' }]"
				/>
				<van-field
					required
					:error="false"
					disabled
					clickable
					name="sex"
					:value="info.sex"
					label="选择性别"
					placeholder="请选择性别"
					@click="!isSubmit ? (showSexPicker = true) : (showSexPicker = false)"
					:rules="[{ required: true, message: '请选择性别' }]"
				/>
				<van-popup v-model="showSexPicker" position="bottom">
					<van-picker
						title="性别"
						show-toolbar
						:columns="sexColumns"
						@confirm="onSexConfirm"
						@cancel="showSexPicker = false"
						@change="onSexChange"
					/>
				</van-popup>
				<van-field
					required
					:error="false"
					:disabled="isSubmit"
					v-model="info.phone"
					name="phone"
					label="手机号"
					placeholder="请输入手机号"
					:rules="[
						{ required: true, message: '请填写手机号' },
						{ pattern: phonePattern, message: '请输入正确格式手机号' },
					]"
				/>
				<van-field
					required
					:error="false"
					:disabled="isSubmit"
					v-model="info.idCard"
					name="idCard"
					label="身份证"
					placeholder="请输入身份证"
					:rules="[
						{ required: true, message: '请填写身份证' },
						{ pattern: idCardPattern, message: '请输入正确格式身份证号' },
					]"
				/>
				<!-- <van-field
					clickable
					name="area"
					:value="`${info.provinceName}/${info.cityName}/${info.countryName}`"
					label="来源"
					placeholder="点击选择省市区"
					@click="showArea = true"
				/> -->
				<van-field
					required
					clickable
					:disabled="isSubmit"
					name="source"
					:value="`${info.provinceName}/${info.cityName}/${info.countryName}`"
					label="来源"
					placeholder="点击选择省市区"
					@click="!isSubmit ? (showArea = true) : (showArea = false)"
					:rules="[{ required: true, message: '请选择来源城市地区' }]"
				/>
				<van-popup v-model="showArea" position="bottom">
					<van-area
						:area-list="areaList"
						@confirm="onAreaConfirm"
						@cancel="showArea = false"
						@change="onAreaChange"
						:value="info.source"
					/>
				</van-popup>
				<van-field
					required
					:error="false"
					:disabled="isSubmit"
					v-model="info.residence"
					name="residence"
					label="居住地"
					placeholder="请输入居住地"
					:rules="[{ required: true, message: '请填写居住地' }]"
				/>
				<van-field
					required
					:error="false"
					:disabled="isSubmit"
					v-model="info.temperature"
					name="temperature"
					label="体温"
					placeholder="请输入体温"
					:rules="[{ required: true, message: '请填写体温' }]"
				/>
				<van-field
					required
					:error="false"
					disabled
					clickable
					name="prove"
					:value="info.prove"
					label="是否持有核酸检测证明"
					placeholder="请选择是否"
					@click="
						!isSubmit ? (showProvePicker = true) : (showProvePicker = false)
					"
					:rules="[{ required: true, message: '请选择' }]"
				/>
				<van-popup v-model="showProvePicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="nucleaseColumns"
						@confirm="onNucleaseConfirm"
						@cancel="showProvePicker = false"
					/>
				</van-popup>
				<van-field name="images" label="个人照片">
					<template #input>
						<van-uploader
							v-model="info.images"
							:after-read="afterRead"
							multiple
							:max-count="1"
							:disabled="isSubmit"
							:deletable="isSubmit"
						/>
					</template>
				</van-field>
				<div style="margin: 16px 60px 0;padding:0 0 20px">
					<van-button
						round
						block
						type="info"
						native-type="submit"
						:disabled="isSubmit"
						>{{!isSubmit?"提交":"提交成功"}}</van-button
					>
				</div>
			</van-form>
		</div>
	</div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
	data() {
		return {
			showSexPicker: false,
			showArea: false,
			showProvePicker: false,
			sexColumns: ["男", "女"],
			nucleaseColumns: ["是", "否"],
			isSubmit: false,
			// areaList2:[],
			areaList,
			info: {
				name: "",
				sex: null,
				phone: "",
				idCard: "",
				provinceName: "北京市",
				cityName: "北京市",
				countryName: "东城区",
				source: "110101",
				residence: "",
				temperature: "",
				prove: null,
				images: [],
			},
			form: {
				name: "",
				sex: null,
				phone: "",
				idCard: "",
				// provinceName: "北京市",
				// cityName: "北京市",
				// countryName: "东城区",
				source: "110101",
				residence: "",
				temperature: "",
				prove: null,
				images: [],
			},
			phonePattern: /^1[3456789]\d{9}$/,
			idCardPattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
			imageUrl: "",
			callbackId: null,
		};
	},
	created() {
		if (this.$route.params.info) {
			this.info = JSON.parse(this.$route.params.info);
			// this.callbackId = this.info.id;
			console.log(111111, this.info, this.callbackId);
		}
		// console.log(this.$route.params.info)
	},
	mounted() {
		window.fullPathForm = window.location.href;
		// console.log(window.fullPathForm, "&&&&&&&&&", window.location);
	},
	methods: {
		// // 手机号验证
		// phoneValidator(val) {
		// 	console.log("val",val);
		//   return /^1[3456789]\d{9}$/.test(val);
		// },
		// idCardValidator(val){
		// 	return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
		// },
		// 选择来源城市
		onAreaConfirm(value) {
			this.info.source = value[2].code;
			this.info.provinceName = value[0].name;
			this.info.cityName = value[1].name;
			this.info.countryName = value[2].name;
			this.form.source = value[2].code;
			this.showArea = false;
		},
		onAreaChange(e) {
			//console.log(e);
		},
		// 性别
		onSexChange(e) {
			//console.log(e);
		},
		onSexConfirm(e) {
			this.info.sex = e;
			if (e == "男") {
				this.form.sex = 1;
			} else if (e == "女") {
				this.form.sex = 2;
			}
			this.showSexPicker = false;
		},
		// 是否有核酸证明
		onNucleaseConfirm(e) {
			this.info.prove = e;
			if (e == "是") {
				this.form.prove = 1;
			} else if (e == "否") {
				this.form.prove = 0;
			}
			this.showProvePicker = false;
		},
		// 文件提交
		// afterRead(file,detail) {
		afterRead(file) {
			let param = new FormData();
			param.append("file", file.file);
			// console.log('detail',detail);
			this.$axios3
				.post("/sys/common/upload", param, {
					Headers: { "Content-Type": "multipart/form-data" },
				})
				.then((res) => {
					this.info.images = [];
					this.imageUrl = res.data.message;
					this.info.images.push({
						url: "https://zhsq.jlxrmtzx.com:3012/zhsq/" + res.data.message,
					});
				});
		},

		onSubmit(values) {
			if (values.prove == "是") {
				values.prove = 1;
			} else {
				values.prove = 0;
			}
			if (values.sex == "男") {
				values.sex = 1;
			} else {
				values.sex = 2;
			}
			values.images = this.imageUrl;
			values.source = this.info.source;
			this.$axios3
				.post("/wechat/epidemicregistration/add", values)
				.then((res) => {
					console.log(res);
					this.isSubmit = true;
				});
		},
		// onRouteConfirm(value) {
		//   this.travelRoute=value
		// 	this.routeSelectList.map((item) => {
		// 		if (item.label == value) {
		// 			this.form.travelRoute = item.value;
		// 		}
		// 	});
		//   this.showRoutePicker=false
		// },
		onConfirm(value) {
			this.selectList.map((item) => {
				if (item.label === value) {
					this.form.teamNature = item.value;
				}
			});

			this.teamNature = value;
			this.showTeamPicker = false;
		},
		onDateConfirm(value) {
			var date = new Date(+value + 8 * 3600 * 1000)
				.toISOString()
				.replace(/T/g, " ")
				.replace(/\.[\d]{3}Z/, "");
			this.form.travelTime = date;
			this.showDatePicker = false;
		},
		onDateConfirm2(value) {
			var date = new Date(+value + 8 * 3600 * 1000)
				.toISOString()
				.replace(/T/g, " ")
				.replace(/\.[\d]{3}Z/, "");
			this.form.enterTime = date;
			this.showDatePicker2 = false;
		},
	},
};
</script>

<style lang="less" scoped>
.travel-shell {
	padding: 20px 20px;
	.travel-title {
		font-size: 18px;
		text-align: center;
		/* font-weight: bold; */
		margin: 0 0 30px;
	}
	.van-button {
		height: 40px;
		font-size: 15px;
	}
	/deep/.van-cell {
		padding: 12px 18px;
		background: none;
		flex-direction: column;
		&::after {
			border-bottom: 1px solid #bdbfc3;
		}
		.van-field__value {
			input {
				border-radius: 4px;
				height: 30px;
				padding: 0 8px;
				background-color: #fff;
				-webkit-user-select: text;
			}
			.van-field__body {
				padding: 0 8px;
				background-color: #fff;
				border-radius: 4px;
				.van-radio-group {
					width: 100%;
					flex-direction: column;
					margin: 8px 0;
					.van-radio--horizontal {
						margin-right: 0;
						&:not(:first-child) {
							border-top: 1px solid #bdbfc3;
							margin-top: 6px;
							padding-top: 6px;
						}
					}
				}
			}
		}
		.van-field__label {
			white-space: nowrap;
			color: #646566;
		}
	}
}
</style>
